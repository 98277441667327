const functions =  {
    availableMethods() {
        return [
            'index',
            'update',
            'tenors',
            'all',
        ]
    },

    tenors(data, cancelKey) {
        const url = `${this.modelName}/tenors`

        return this._callApi(url, 'get', data, cancelKey)
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', data, cancelKey)
    },

    update(id, data, cancelKey) {
        const url = `${this.modelName}/${id}`

        return this._callApi(url, 'put', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
