const functions =  {
    availableMethods() {
        return [
            'index',
            'show',
            'approve',
            'reject',
            'activate',
            'deactivate',
            'resendApprovalEmail',
        ]
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', {}, cancelKey)
    },

    approve(companyId, cancelKey) {
        const url = `${this.modelName}/${companyId}/approve`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    reject(companyId, cancelKey) {
        const url = `${this.modelName}/${companyId}/reject`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    activate(companyId, cancelKey) {
        const url = `${this.modelName}/${companyId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(companyId, cancelKey) {
        const url = `${this.modelName}/${companyId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    resendApprovalEmail(companyId, cancelKey) {
        const url = `${this.modelName}/${companyId}/resend-approval-email`

        return this._callApi(url, 'post', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
